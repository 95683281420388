#enLink, #deLink {
  opacity: 0.7;
}

#sidebar {
  #sidebarYourSites {
    .login {
      text-align: center;
      margin-top: 15px;
    }

    .consultationList {
      > li {
        padding: 5px 0;

        -webkit-hyphens: auto;
        word-break: break-word;
        -moz-hyphens: auto;
        hyphens: auto;
        overflow: hidden;

        span.orga {
          font-size: 0.9em;
          color: #767676;
          display: block;
        }
      }
    }
  }
}

.managerAllsitesList {
  ul {
    list-style: none;
    display: block;
    margin: 0;
    padding: 0;

    > li {
      padding: 5px 0;
      display: block;
      -webkit-hyphens: auto;
      word-break: break-word;
      -moz-hyphens: auto;
      hyphens: auto;
      overflow: hidden;

      @media screen and (min-width: 1024px) {
        float: left;
        width: 50%;
        height: 60px;
      }

      span.orga {
        font-size: 0.9em;
        color: #767676;
        display: block;
      }
    }
  }
}

.manager-navbar-orgaLink {
  a {
    margin-left: 0 !important;
  }

  .orga {
    font-size: 0.9em;
    color: #767676;
    display: block;
  }
}

.managerHelpPage {
  font-size: 16px;
  .well &.content {
    padding: 15px 40px 30px;
  }
  h2 {
    margin-top: 40px;
  }
  h3 {
    margin-top: 25px;
  }
  ul, ol {
    margin-top: 25px;
    margin-bottom: 25px;
  }
  li {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .toc {
    list-style-type: none;
    margin: 20px 0 60px;
    padding: 0;
    ul {
      margin-top: 0;
    }
    > li > a {
      font-weight: bold;
    }
  }
  .tocFlat {
    margin: 30px 20px 30px;
    padding: 0;
  }
  .helpFigure {
    margin-bottom: 10px;
    margin-top: 10px;
    img {
      max-width: 100%;
    }
    figcaption {
      margin-top: -20px;
      margin-bottom: 20px;
      font-size: 0.8em;
      font-style: italic;
    }
    &.center {
      width: 70%;
      margin-left: auto;
      margin-right: auto;
    }
    @media screen and (min-width: 800px) {
      &.right {
        width: 50%;
        float: right;
        margin-left: 20px;
      }
    }
    &.bordered {
      border: solid 1px grey;
    }
  }
}

.infoSite {
  ul {
    margin-bottom: 25px;
    li {
      margin-top: 7px;
    }
  }
  h3 {
    font-size: 1.2em;
    line-height: 1.5;
    margin: 0;
  }
}

.statusReferenceTable {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
  col.name {
    width: 220px;
  }
  tr {
    td, th {
      padding-right: 10px;
      padding-bottom: 6px;
      padding-top: 6px;
      border-bottom: solid 1px #ddd;
    }
  }
}
